import * as React from "react";
import DefaultLayout from "../layouts/default";
import { SinglePostWrap } from "../components/LayoutComponents.stc";
import demo from "../assets/img/malanargia_galathea_schachbrett_haldenstein_070617_1.jpg";
import { graphql } from "gatsby";
import { SeiteType } from "../components/types";
import { Helmet } from "react-helmet";

const sliderItems = [
  {
    src: demo,
    altText: "Insektenname",
    caption: "Kategorie",
  },
  {
    src: demo,
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    src: demo,
    altText: "Slide 3",
    caption: "Slide 3",
  },
];

export const query = graphql`
  query CMSPageQuery($slug: String) {
    cms {
      seite: entry(slug: [$slug]) {
        ... on CMS_seiten_seiten_Entry {
          title
          htmlInhalt
          slug
        }
      }
    }
  }
`;

interface CMSProps {
  pageContext: {
    slug: string;
  };
  data: {
    cms: {
      seite: SeiteType;
    };
  };
}

const Cms: React.FC<CMSProps> = (props) => {
  const {
    data: {
      cms: {
        seite: { title, htmlInhalt },
      },
    },
  } = props;

    let pageTitle = title!;
    pageTitle = pageTitle + " - Insects";

  return (
    <DefaultLayout sliderItems={sliderItems}>
      <Helmet
        title={pageTitle}
        
      ></Helmet>
      <div dangerouslySetInnerHTML={{ __html: htmlInhalt! }}></div>
    </DefaultLayout>
  );
};

export default Cms;
